import React, { useState, useEffect, useRef } from "react";

// Configuration
const CONFIG = {
  USE_STATIC_DATA: true, // Set to false to fetch from backend
  API_ENDPOINT:
    "https://sb-apis.ibunchng.com/marketplace/v1/public/advert/slides", // Your backend endpoint
  FETCH_INTERVAL: 5 * 60 * 1000, // Refresh data every 5 minutes
  IDLE_TIMEOUT: 1 * 60 * 1000, // 1 minute
};

// Static slides - used when USE_STATIC_DATA is true
const STATIC_SLIDES = [
  {
    type: "image",
    src: "https://cdn-sb-app-assets-n1.s3.amazonaws.com/discount-loyalty-ad-nexn.png",
    title: "Reward Your Loyal Customers!",
    text: "Launch Loyalty Programs and Create Discount Offers with Ease at StoreBounty.com.",
  },
  {
    type: "image",
    src: "https://cdn-sb-app-assets-n1.s3.amazonaws.com/paperless-ad-wrdw.png",
    title: "Go Paperless, Stay Organized!",
    text: "Manage Inventory and Sales Effortlessly at StoreBounty.com.",
  },
  {
    type: "text",
    title: "Free Online Store",
    text: "Launch Your Online Store in Minutes",
    subtext: "Get started for free today at StoreBounty.com.",
    textAnimation: "fade-up",
  },
  {
    type: "text",
    title: "Connect and Grow",
    text: "Seamless Payment Integration",
    subtext:
      "Accept multiple payment methods effortlessly with StoreBounty.com.",
    textAnimation: "slide-in",
  },
  {
    type: "text",
    title: "Stay on Top of Your Business",
    text: "AI-Powered Reports",
    subtext:
      "Gain insights into sales, inventory, and customers at StoreBounty.com.",
    textAnimation: "fade-up",
  },
  {
    type: "image",
    src: "https://cdn-sb-app-assets-n1.s3.amazonaws.com/sales-and-structure-hqbo.jpg",
    title: "Take Your Business to the Next Level.",
    text: "Empower your business with sales tools at StoreBounty.com.",
  },
  {
    type: "text",
    title: "Reach Customers Anywhere",
    text: "Mobile-Friendly Platform",
    subtext: "Access business tools on the go from StoreBounty.com.",
    textAnimation: "slide-in",
  },
  {
    type: "text",
    title: "Work Smarter, Not Harder",
    text: "Inventory Management Made Easy",
    subtext: "Track stock levels effortlessly with StoreBounty.com.",
    textAnimation: "bounce",
  },
  {
    type: "image",
    src: "https://cdn-sb-app-assets-n1.s3.amazonaws.com/fast-checkout-ad-wpcs.png",
    title: "Fast and Seamless Checkout!",
    text: "Speed up sales with StoreBounty.com's mobile and web POS.",
  },
  {
    type: "text",
    title: "Boost Your Sales",
    text: "Integrated Marketing Tools",
    subtext: "Run promotions and engage customers with StoreBounty.com.",
    textAnimation: "fade-up",
  },
];

const DEFAULT_IMAGE_DURATION = 5000; // 5 seconds for images

export default function IdleTimeDisplay() {
  const [isIdle, setIsIdle] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState([]);
  const videoRef = useRef(null);
  const slideTimeoutRef = useRef(null);

  // Fetch slides from backend
  const fetchSlides = async () => {
    try {
      const response = await fetch(CONFIG.API_ENDPOINT);
      if (!response.ok) throw new Error("Failed to fetch slides");
      const data = await response.json();
      // Check if 'slides' exists and is an array
      if (data.slides && Array.isArray(data.slides)) {
        setSlides(data.slides);
      } else {
        throw new Error(
          "Invalid data format: 'slides' key not found or not an array"
        );
      }
    } catch (error) {
      console.error("Error fetching slides:", error);
      // Fallback to static slides if fetch fails
      setSlides(STATIC_SLIDES);
    }
  };

  // Initialize and periodically update slides if using backend
  useEffect(() => {
    if (CONFIG.USE_STATIC_DATA) {
      setSlides(STATIC_SLIDES);
    } else {
      fetchSlides(); // Initial fetch
      const fetchInterval = setInterval(fetchSlides, CONFIG.FETCH_INTERVAL);
      return () => clearInterval(fetchInterval);
    }
  }, []);

  // Idle detection
  useEffect(() => {
    let idleTimer;

    const resetTimer = () => {
      clearTimeout(idleTimer);
      setIsIdle(false);
      idleTimer = setTimeout(() => setIsIdle(true), CONFIG.IDLE_TIMEOUT);
    };

    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
      "click",
      "touchmove",
    ];
    events.forEach((event) => document.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      clearTimeout(idleTimer);
      events.forEach((event) =>
        document.removeEventListener(event, resetTimer)
      );
    };
  }, []);

  // Handle slide transitions
  const moveToNextSlide = () => {
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  // Slideshow control
  useEffect(() => {
    if (!isIdle) return;

    const currentContent = slides[currentSlide];

    if (slideTimeoutRef.current) {
      clearTimeout(slideTimeoutRef.current);
    }

    if (currentContent.type === "video" && videoRef.current) {
      const handleVideoEnd = () => {
        moveToNextSlide();
      };

      videoRef.current.addEventListener("ended", handleVideoEnd);

      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener("ended", handleVideoEnd);
        }
      };
    } else {
      // For images and text slides, use default duration
      slideTimeoutRef.current = setTimeout(
        moveToNextSlide,
        DEFAULT_IMAGE_DURATION
      );

      return () => {
        if (slideTimeoutRef.current) {
          clearTimeout(slideTimeoutRef.current);
        }
      };
    }
  }, [isIdle, currentSlide, slides]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (slideTimeoutRef.current) {
        clearTimeout(slideTimeoutRef.current);
      }
    };
  }, []);

  if (!isIdle) return null;

  const currentContent = slides[currentSlide];

  // Animation classes based on type
  const getAnimationClass = (animation) => {
    switch (animation) {
      case "fade-up":
        return "animate-fade-up";
      case "slide-in":
        return "animate-slide-in";
      case "bounce":
        return "animate-bounce";
      default:
        return "animate-fade-up";
    }
  };

  const renderContent = () => {
    switch (currentContent.type) {
      case "image":
        return (
          <div className="w-full h-full bg-white flex items-center justify-center p-8">
            <div className="relative w-full h-full flex items-center justify-center">
              <img
                src={currentContent.src}
                alt={currentContent.title}
                className="max-w-[90%] max-h-[90%] object-contain"
                style={{
                  objectFit: "contain",
                  margin: "auto",
                }}
              />
            </div>
          </div>
        );
      case "video":
        return (
          <video
            ref={videoRef}
            key={currentSlide}
            className="max-w-full max-h-full object-contain"
            autoPlay
            playsInline
            muted
            onError={(e) => console.error("Video error:", e)}
          >
            <source src={currentContent.src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case "text":
        return (
          <div className="flex flex-col items-center justify-center text-white text-center p-8">
            <h1
              className={`text-6xl font-bold mb-6 ${getAnimationClass(
                currentContent.textAnimation
              )}`}
            >
              {currentContent.title}
            </h1>
            <p
              className={`text-4xl mb-4 ${getAnimationClass(
                currentContent.textAnimation
              )} animation-delay-150`}
            >
              {currentContent.text}
            </p>
            {currentContent.subtext && (
              <p
                className={`text-2xl text-gray-300 ${getAnimationClass(
                  currentContent.textAnimation
                )} animation-delay-300`}
              >
                {currentContent.subtext}
              </p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black">
      <div className="relative w-full h-full flex items-center justify-center">
        {renderContent()}

        {/* Overlay text for image and video types */}
        {(currentContent.type === "image" || currentContent.type === "video") &&
          (currentContent.title || currentContent.text) && (
            <div className="absolute bottom-0 left-0 right-0 bg-black/50 p-6 text-white">
              {currentContent.title && (
                <h2 className="text-4xl font-bold mb-2">
                  {currentContent.title}
                </h2>
              )}
              {currentContent.text && (
                <p className="text-xl">{currentContent.text}</p>
              )}
            </div>
          )}
      </div>
    </div>
  );
}
