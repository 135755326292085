import React, { createContext, useState, useEffect, useContext } from "react";
import { terminologyDictionary } from '../data/terminology';
import StorageServices from "../services/StorageServices";

const TerminologyContext = createContext();

export function TerminologyProvider({ children }) {
  const [terms, setTerms] = useState(terminologyDictionary.default);

  useEffect(() => {
    const activeStore = StorageServices.get("SB-Active-Store")
      ? JSON.parse(StorageServices.get("SB-Active-Store"))
      : null;
    const bizType = activeStore?.business_type ? activeStore.business_type : "retail";
    setTerms(terminologyDictionary[bizType]);
  }, []);

  const singularize = (word) => {
    if (word.endsWith("ies")) return word.slice(0, -3) + "y";
    if (word.endsWith("es")) return word.slice(0, -2);
    if (word.endsWith("s")) return word.slice(0, -1);
    return word;
  };

  const transformWord = (word, form, transform) => {
    // Split the word into parts to handle each word separately
    const parts = word.split(' ');
    if (parts.length > 1) {
      return parts.map(part => transformWord(part, form, transform)).join(' ');
    }

    // Now handling single words
    const isAcronym = word === word.toUpperCase() && word.length > 1;
    if (isAcronym) {
      return word; // Return acronym unchanged
    }

    // Rest of your existing code for non-acronym words
    const lowerWord = word.toLowerCase();
    const lowerForm = form.toLowerCase();

    let term = terms[lowerWord]?.[lowerForm] || terminologyDictionary.default[lowerWord]?.[lowerForm];
    if (!term) {
      const singularWord = singularize(lowerWord);
      term = terms[singularWord]?.[lowerForm] || terminologyDictionary.default[singularWord]?.[lowerForm];
    }

    if (!term) {
      term = word;
    }

    switch (transform.toUpperCase()) {
      case "U":
        return term.toUpperCase();
      case "L":
        return term.toLowerCase();
      case "C":
        return term.charAt(0).toUpperCase() + term.slice(1).toLowerCase();
      default:
        return term;
    }
  };

  const getTerm = (key, form = "singular", transform = "") => {
    // Split the key into words, apply transformations, and reassemble
    return key
      .split(" ")
      .map((word) => transformWord(word, form, transform))
      .join(" ");
  };

  return (
    <TerminologyContext.Provider value={{ getTerm }}>
      {children}
    </TerminologyContext.Provider>
  );
}

export const useTerminology = () => useContext(TerminologyContext);
