// /src/data/terminology.js
export const terminologyDictionary = {
    default: {
      customer: { singular: "customer", plural: "customers" },
      order: { singular: "order", plural: "orders" },
      // Add more terms here
    },
    hospital: {
      customer: { singular: "patient", plural: "patients" },
      order: { singular: "appointment", plural: "appointments" },
    },
    travel_agency: {
      customer: { singular: "client", plural: "clients" },
      order: { singular: "booking", plural: "bookings" },
    },
    retail: {
      customer: { singular: "customer", plural: "customers" },
      order: { singular: "order", plural: "orders" },
    },
  };
  